<template lang="pug">

.about-page
	a.whatsappWrapper(href="https://wa.me/+917579175791?text=I'm%20inquiring%20about%20the%20properties%20from%20Website!")
		img(src='../assets/img/WhatsApp.svg')
	display-header(:img="require('@/assets/overscans/sports_2.jpg')",headline="Party Hard & Play Harder")
	.container
		right-side-section(
			title="Badminton"
			:img='require("@/assets/img/final_3d_images/low/img086.jpg")'
			description="Badminton is the World’s fastest racket sports and World’s second most popular participation sport after football. As a flexible sport, playing badminton allows players to move their joints in a healthy way – the neck, shoulders, and back, as well. And because the game keeps bodies in constant motion, eyes do the same, which benefit for protecting healthy vision, whether the player is young or old. Good badminton players must manage their breath well and benefits the respiratory system. it’s an easy and effective way to keep active while boosting your mood and energy levels and relieving stress. Play Badminton vigorously for an hour, you can burn up to 480 calories."
		)
			<span style="font-weight: 600;">Note:</span> Non marking shoes are compulsory for Badminton.

		left-side-section(
			title="Squash"
			:img='require("@/assets/img/final_3d_images/low/img087.jpg")'
			description="We are introducing Squash sport first time in Kutch Region. Squash is an ideal lifestyle sport enjoyed by recreational and competitive players. Squash is a brutal sport. You have to be extremely quick to get to the ball but you also have to have great endurance and recovery to keep getting that ball back time and time again."
		)
			<span style="font-weight: 600;">Note:</span> Non marking shoes are compulsory for Squash.


</template>


<script>
import RightSideSection from '../components/XRightSideSection.vue'
import LeftSideSection from '../components/XLeftSideSection.vue'
import DisplayHeader from '../components/DisplayHeader.vue'
export default {
	components: {
    RightSideSection,
		DisplayHeader,
    LeftSideSection
  },
}
</script>

<style>
</style>